import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import HeroNews from "../components/Sections/HeroNews"
import StyledBackgroundNews from "../components/BackgroundNews"

import "./mystyles.scss"

const NewsPage = ({ location }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <StyledBackgroundNews>
        <HeroNews location={location} data="header.nav.item-4" />
      </StyledBackgroundNews>
    </>
  )
}

export default injectIntl(NewsPage)
